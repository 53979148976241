import swal from "sweetalert2";

class Alert {

    isAlertShowing = false;

    /**
     * Close the showing alert if applicable.
     * This should not close popups that require user interaction.
     */
    closeAlert() {
        if (this.isAlertShowing) {
            swal.close();
        }
    }

    /**
     * Show an error alert. Parses the error from given JSON which can be either
     * an axios caught error or an axios JSON response.
     * @param msg Response message to parse details/message from.
     */
    showErrorMessage(msg) {
        this.isAlertShowing = true;
        try {
            let json = this.getJson(msg);
            let message = json["message"];
            let details = json["details"];
            console.log("Showing error: message=" + message + ", details=" + details);
            this.showError(message, details);
        } catch (err) {
            console.log("Failed to show error alert", err);
        }
    }

    /**
     * Show an error message with given message and details
     * @param message Message (title)
     * @param details Details (text)
     */
    showError(message, details) {
        this.isAlertShowing = true;
        swal.fire({
            icon: 'error',
            title: message,
            text: details,
            iconColor: '#f46a6a',
            toast: true,
            position: 'top-end',
            timer: 60000,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true,
        }).then(r => { this.isAlertShowing = false; });
    }

    /**
     * Show a success message with given message and details
     * <p>Message shown contains an OK button.
     * @param message Message (title)
     * @param details Details (text)
     * @param onComplete Closure to run after message
     */
    showSuccess(message, details, onComplete) {
        this.isAlertShowing = true;
        swal.fire({
            icon: 'success',
            title: message,
            text: details,
            iconColor: '#556ee6',
        }).then(r => {
            if (onComplete != null) onComplete();
            this.isAlertShowing = false;
        });
    }

    /**
     * Show a success message with given message and details
     * <p>Message shown contains an OK button.
     * @param message Message (title)
     * @param details Details (HTML)
     * @param onComplete Closure to run after message
     */
    showSuccessHtml(message, details, onComplete) {
        this.isAlertShowing = true;
        swal.fire({
            icon: 'success',
            title: message,
            html: details,
            iconColor: '#556ee6',
        }).then(r => {
            if (onComplete != null) onComplete();
            this.isAlertShowing = false;
        });
    }

    /**
     * Show a success toast with given message and details
     * @param message Message (title)
     * @param details Details (text)
     * @param onComplete Closure to run after message
     */
    showSuccessToast(message, details, onComplete) {
        this.isAlertShowing = true;
        if (message == null) message = "OPERATION_OK";
        swal.fire({
            icon: 'success',
            title: message,
            text: details,
            iconColor: '#556ee6',
            toast: true,
            position: 'top-end',
            timer: 10000,
            timerProgressBar: true,
            showConfirmButton: false,
        }).then(r => {
            if (onComplete != null) onComplete();
            this.isAlertShowing = false;
        });
    }

    /**
     * Open the delete popup and allow user to delete or cancel
     * @param onDelete   Closure to call when user accepts (preferably returning Promise<boolean>)
     * @param successMsg Message to show if the delete succeeded
     * @param onSuccess  Closure to call when operation has succeeded
     * @param onCancel   Closure to call when user cancels
     * @param customText Optional, custom text String. Default value is "You won't be able to revert this!".
     */
    showDeletePopup(onDelete, successMsg, onSuccess, onCancel, customText) {
        if (successMsg == null) successMsg  = "Successfully deleted";
        if (onSuccess  == null) onSuccess   = () => {};
        if (onCancel   == null) onCancel    = () => {};
        if (!customText) {
            customText = "You won't be able to revert this!";
        }
        swal.fire({
            title: 'Are you sure?',
            text: customText,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#556ee6',
            confirmButtonColor: '#f46a6a',
            iconColor: '#f1b44c',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                onDelete().then((result) => {
                    if (result) {
                        swal.fire(
                            'Deleted!',
                            '' + successMsg,
                            'success'
                        ).then((result) => {
                            console.log("Delete succeeded. Calling onSuccess()");
                            onSuccess();
                        });
                    } else {
                        console.log("Delete failed");
                    }
                });
            } else {
                console.log("User did not confirm delete");
                onCancel();
            }
        }).catch((error) => {
            console.log("Delete failed");
            console.log(error);
            Alert.showErrorMessage(error);
        });
    }

    getJson(message) {
        console.log("Logging: " + JSON.stringify(message))
        try {
            if (message.message != null) {
                // Given message is already a valid JSON
                return message;
            }
            if (message.response != null) {
                return JSON.parse(JSON.stringify(message.response.data));
            }
            if (message.data != null) {
                return JSON.parse(JSON.stringify(message.data));
            }
            if (typeof message === 'string') {
                return {"message": JSON.stringify(message), "details": "OK" }
            }
            return JSON.parse(JSON.stringify(message.response.data));
        } catch (err) {
            try {
                return JSON.parse(JSON.stringify(message.data));
            } catch (err2) {
                return {"message": "Operation failed", "details": "Unknown error"}
            }
        }
    }

    static getJson(message) {
        try {
            return JSON.parse(JSON.stringify(message.response.data));
        } catch (err) {
            try {
                return JSON.parse(JSON.stringify(message.data));
            } catch (err2) {
                return {"message": "Operation failed", "details": "Unknown error"}
            }
        }
    }


    /**
     * Show an error message with given message and details
     * @param message Message (title)
     * @param details Details (text)
     */
    static showError(message, details) {
        this.isAlertShowing = true;
        swal.fire({
            icon: 'error',
            title: message,
            text: details,
        }).then(r => { this.isAlertShowing = false; });
    }

    /**
     * Show an error alert. Parses the error from given JSON which can be either
     * an axios caught error or an axios JSON response.
     * @param msg Response message to parse details/message from.
     */
    static showErrorMessage(msg) {
        this.isAlertShowing = true;
        try {
            let json = this.getJson(msg);
            let message = json["message"] != null ? json["message"] : "INTERNAL_ERROR";
            let details = json["details"];
            console.log("Showing error: message=" + message + ", details=" + details);
            this.showError(message, details);
        } catch (err) {
            console.log("Failed to show error alert", err);
        }
    }

    /**
     * Show error popup. User needs to press ok to exit popup.
     */
    showErrorPopup(message, details, onComplete) {
        swal.fire({
            icon: 'error',
            title: message,
            text: details,
            iconColor: '#556ee6',
        }).then(r => {
            if (onComplete != null) onComplete();
            this.isAlertShowing = false;
        });
    }
}
export default new Alert();

/**
 * Toast Alert that contains adjusted colors and default settings.
 * Usage:
 * <pre>
 * import {Toast} from "@/helpers/Alert";
 *
 * Toast.fire({
 *   title: 'Login succeeded',
 *   text: 'Welcome ' + this.getFullName(),
 * });
 * </pre>
 */
export const Toast = swal.mixin({
    icon: 'success',
    iconColor: '#556ee6',
    confirmButtonColor: '#556ee6',
    cancelButtonColor: '#f46a6a',
    toast: true,
    position: 'top-end',
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
});