import BaseService from "@/services/BaseService";

class MobileUserService extends BaseService {
    constructor(config) {
        super(config);
    }

    /**
     * Activates a new PBY app for MobileUser with specified MSISDN
     * @param msisdn
     * @returns {Promise<*>}
     */
    async activateApp(msisdn) {
        return this.post("/mu/activate/app/" + msisdn)
    }

    /**
     * Activates a SIM card for MobileUser with specified MSISDN
     * @param msisdn
     * @returns {Promise<*>}
     */
    async activateSim(msisdn, payload) {
        return this.post("/mu/activate/sim/" + msisdn, payload);
    }

    /**
     * Get details of a single user's activation progress
     * @param msisdn
     * @returns {Promise<*>}
     */
    async getActivationProgress(msisdn) {
        return this.get("/mu/activation/progress/" + msisdn);
    }

    /**
     * Binds a SIM card to a specific MSISDN
     * @param imsi
     * @param msisdn
     * @returns {Promise<*>}
     */
    async bindSim(msisdn, payload) {
        return this.post("/mu/bindsim/" + msisdn, payload);
    }

    /**
     * Create a new Mobile User
     * @param msisdn
     * @param payload - iccid, state, language, organization, title, stateorprovince, role, crn
     * @returns {Promise<*>}
     */
    async createMobileUser(msisdn, payload) {
        return await this.post("/mu/create/" + msisdn, payload);
    }

    /**
     * Deactivate an app based MobileID
     * @param msisdn
     * @returns {Promise<*>}
     */
    async deactivateApp(msisdn) {
        return this.post("/mu/deactivate/app/" + msisdn);
    }

    /**
     * Deactivate an SIM based MobileID
     * @param msisdn
     * @returns {Promise<*>}
     */
    async deactivateSim(msisdn) {
        return this.post("/mu/deactivate/sim/" + msisdn);
    }

    /**
     * Delete a Mobile User
     * @param msisdn
     * @returns {Promise<*>}
     */
    async deleteMobileUser(msisdn) {
        return this.post("/mu/delete/" + msisdn);
    }

    /**
     * Get details of a single mobile user specified by MSISDN
     * @param msisdn
     * @param getApp - Get APP details? false by default
     * @param getSim - Get SIM details? false by default
     * @param getStats - Get Stats? false by default
     * @param getCerts - Get Certificates? false by default
     * @returns {Promise<*>}
     */
    async getSingleMobileUser(
        msisdn,
        getApp = false,
        getSim = false,
        getStats = false,
        getCerts = false
    )
    {
        let url = "/mu/get/" + msisdn;
        if (getApp)   { url += "?getapp=true" }
        if (getSim)   { url += "&getsim=true" }
        if (getStats) { url += "&getstats=true" }
        if (getCerts) { url += "&getcerts=true" }
        return this.get(url);
    }

    /**
     * Get details of all Mobile Users that match query parameters
     * @param requestUrl - skipcache, limit, startAt, targetType, targetValue, route
     * @returns {Promise<*>}
     */
    async getAllMobileUsers(requestUrl) {
        return this.get("/mu/list" + requestUrl);
    }

    /**
     * Lists all certificates user with specified MSISDN has
     * @param msisdn
     * @returns {Promise<*>}
     */
    async getCertificatesByMsisdn(msisdn, requestUrl){
        if (requestUrl === null) {
            return this.post("/mu/listcertificates/" + msisdn);
        } else {
            return this.post("/mu/listcertificates/" + msisdn + requestUrl);
        }

    }

    /**
     * Ping a MobileUser
     * @param msisdn
     * @param isApp - Boolean to check if we should ping App instead of SIM
     * @returns {Promise<*>}
     */
    async pingMobileUser(msisdn, isApp){
        let url = "/mu/ping/" + msisdn;

        if (isApp) {
            url += "&app=true"
        }

        return this.post(url);
    }

    //TODO: This is not used in KWM front end at the moment and requires FormParams usage. Is this feature ever used?
    async provisionMobileUsersFromCsv() {
        return this.post();
    }

    /**
     * Renew Mobile User certificate. Registration profile as base64
     * @param msisdn
     * @param registrationProfileBase64
     * @returns {Promise<*>}
     */
    async renewCertificate(msisdn, registrationProfileBase64) {
        return this.post("/mu/renewcertificate/" + msisdn + "/" + registrationProfileBase64)
    }

    /**
     * Revokes a specified certificate from MobileUser
     * @param msisdn
     * @param certificateProfileBase64
     * @returns {Promise<*>}
     */
    async revokeCertificate(msisdn, certid){
        return this.post("/mu/revokecertificate/" + msisdn + "/" + certid);
    }

    /**
     * Sends a test text message to a MobileUser specified by MSISDN
     * @param msisdn
     * @returns {Promise<*>}
     */
    async textMobileUser(msisdn) {
        return this.post("/mu/text/" + msisdn);
    }

    /**
     * Sends Alauda message to a MobileUser specified by MSISDN
     */
    async messageMobileUser(msisdn){
        return this.post("mu/alaudamessage/" + msisdn);
    }
    /**
     * Updates a MobileUser with values in payload object
     * @param msisdn
     * @param payload - givenname, surname
     * @returns {Promise<*>}
     */
    async updateMobileUserByMsisdn(msisdn, payload) {
        return this.post("/mu/update/" + msisdn, payload);
    }

    /**
     * Suspend Mobile User
     */
    async suspendMobileUser(msisdn){
        return this.post("/mu/suspend/" + msisdn);
    }

    /**
     * Restore Mobile User
     */
    async restoreMobileUser(msisdn){
        return this.post("/mu/restore/" + msisdn);
    }

    /**
     * Changes the MSISDN of an MobileUser
     * @param payload - newMsisdn
     * @param targetMsisdn - MSISDN of the mobile user to change to new MSISDN
     * @returns {Promise<*>}
     */
    async changeMsisdn(payload, targetMsisdn) {
        return this.post("/mu/change/msisdn/" + targetMsisdn, payload);
    }

    /**
     * Changes the ICCID of a MobileUser and SIM card
     * @param payload - payload containing the new ICCID "iccid"
     * @param msisdn - MSISDN of the target MobileUser
     * @returns {Promise<*>}
     */
    async changeIccid(payload, msisdn) {
        return this.post("/mu/change/iccid/" + msisdn, payload);
    }

}

export default MobileUserService;
