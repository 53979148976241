export const menuItems = [
    {
        id: 0,
        label: "menu",
        isTitle: true,
        showAlways: true,
        roleRequired: [""],
    },
    {   // home page should never be accessible to web_enduser role
        id: 1,
        label: "Home",
        link: "/",
        name: "home",
        roleRequired: ["web_admin", "web_register", "web_validator", "web_auditor", "web_readonly"],
        icon: 'bx-home-circle',
        showAlways: false,
    },
    {
        id: 8,
        label: "Self-care",
        link: "/pages/self-care",
        name: "self-care",
        roleRequired: ["web_enduser", "web_admin"],
        icon: 'bx-user-circle',
        showAlways: false,
        requireMobileId: true,
    },
    {
        id: 40,
        label: "User Management",
        link: "/pages/user-management",
        name: "user-management-dropdown",
        icon: 'bx-user',
        roleRequired: ["web_admin", "web_register", "web_validator", "web_readonly"],
        subItems: [
            {
                id: 3,
                label: "User Provisioning",
                link: "/pages/user-provisioning",
                name: "user-provisioning",
                roleRequired: ["web_admin"],
            },
            {
                id: 4,
                label: "App Provisioning",
                link: "/pages/mobileuser/create-app",
                name: "app-provisioning",
                roleRequired: ["web_admin", "web_register"],
            },
            {
                id: 5,
                label: "User List",
                link: "/pages/list-user",
                name: "list-users",
                roleRequired: ["web_admin", "web_register", "web_validator", "web_readonly"],
            },
            {
                id: 6,
                label: "RSSP Client List",
                link: "/pages/list-rssp-clients",
                name: "list-rssp-clients",
                roleRequired: ["web_admin"],
            },
        ]
    },
    {
        id: 9,
        label: "SIM Management",
        link: "/pages/sim-management",
        name: "sim-management-dropdown",
        icon: 'bx-memory-card',
        roleRequired: ["web_admin", "web_register", "web_readonly"],
        subItems: [
            {
                id: 2,
                label: "SIM Provisioning",
                link: "/pages/sim-provisioning",
                name: "sim-provisioning",
                roleRequired: ["web_admin", "web_register"],
            },
            {
                id: 3,
                label: "SIM List",
                link: "/pages/sim-list",
                name: "sim-list",
                roleRequired: ["web_admin", "web_register", "web_readonly"],
            },
        ]
    },
    {
        id: 12,
        label: "AP Management",
        link: "/pages/list-ap",
        name: "application-provider-dropdown",
        roleRequired: ["web_admin", "web_readonly"],
        icon: 'bx-store',

        subItems: [
            {
                id: 12,
                label: "AP Provisioning",
                link: "/pages/create-ap",
                name: "create-ap",
                roleRequired: ["web_admin"],
            },
            {
                id: 13,
                label: "AP List",
                link: "/pages/list-ap",
                name: "list-ap",
                roleRequired: ["web_admin", "web_readonly"],
            },
            {
                id: 26,
                label: "AP Services",
                link: "/pages/ap/services",
                name: "ap-services",
                roleRequired: ["web_admin"],
            },
            {
                id: 14,
                label: "AP Export upload",
                link: "/pages/ap-export-upload",
                name: "ap-upload-export",
                roleRequired: ["web_admin"]
            },
            {
                id: 25,
                label: "Upload Valimo SP",
                link: "/pages/ap/upload-valimo-sp",
                name: "upload-valimo-sp",
                roleRequired: ["web_admin"]
            }
        ],

    },
    {
        id: 50,
        label: "MSSP",
        link: "/",
        name: "logs-dropdown",
        icon: 'bx-list-ul',
        roleRequired: ["web_admin", "web_auditor"],
        subItems: [
            {
                id: 12,
                label: "Kiuru Server List",
                link: "/pages/list-kiuru-servers",
                name: "mssp-kiuru-servers",
                roleRequired: ["web_admin"],
            },
            {
                id: 17,
                label: "MSSP Mesh",
                link: "/pages/list-mssps",
                name: "list-mssps",
                roleRequired: ["web_admin"],
            },
            {
                id: 18,
                label: "CA Connections",
                link: "/pages/ca-connections",
                name: "ca-connections",
                roleRequired: ["web_admin"],
            },
            {
                id: 11,
                label: "Alauda Logs",
                link: "/pages/alauda-logs",
                name: "alauda-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 15,
                label: "PBY Logs",
                link: "/pages/pby-logs",
                name: "pby-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 14,
                label: "MSS Logs",
                link: "/pages/mss-logs",
                name: "mss-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 16,
                label: "Mreg Logs",
                link: "/pages/mreg-logs",
                name: "mreg-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 19,
                label: "Notification Logs",
                link: "/pages/notification-logs",
                name: "notification-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 21,
                label: "MUSAP Link",
                link: "/pages/musap-link",
                name: "musap-link",
                roleRequired: ["web_admin"],
            },

        ],
    },
    {
        id: 60,
        label: "OTA",
        link: "/",
        name: "ota-dropdown",
        icon: 'bx-server',
        roleRequired: ["web_admin", "web_readonly", "web_register"],
        subItems: [
            {
                id: 12,
                label: "OTA Logs",
                link: "/pages/ota-logs",
                name: "ota-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 13,
                label: "OTA Statistics",
                link: "/pages/ota-stats",
                name: "ota-statistics",
                roleRequired: ["web_admin"],
            },
            {
                id: 14,
                label: "OTA Configuration",
                link: "/pages/ota-config",
                name: "ota-config",
                roleRequired: ["web_admin"],
            },
            {
                id: 15,
                label: "OTA File Records",
                link: "/pages/ota-records",
                name: "ota-records",
                roleRequired: ["web_admin", "web_readonly", "web_register"],
            }
        ],
    },
    {
        id: 70,
        label: "WebManager",
        link: "/pages/webuser/create",
        name: "web-user-dropdown",
        icon: 'bx-cog',
        roleRequired: ["web_admin"],
        subItems: [
            {
                id: 9,
                label: "Audit Logs",
                link: "/pages/audit-logs",
                name: "audit-logs",
                roleRequired: ["web_admin", "web_auditor"],
            },
            {
                id: 71,
                label: "Create User",
                link: "/pages/webuser/create",
                name: "create-web-user",
                roleRequired: ["web_admin"]
            },
            {
                id: 72,
                label: "List Users",
                link: "/pages/webuser/list/all",
                name: "list-web-users",
                roleRequired: ["web_admin"]
            },
        ],
    },
    {
        id: 30,
        label: "CLI Tools",
        link: "/pages/cli-tools",
        name: "cli-tools",
        icon: 'bx-terminal',
        roleRequired: ["web_admin"],
    },
    {
        id: 20,
        label: "Logout",
        link: "/logout",
        name: "logout",
        icon: 'bx-log-out',
        showAlways: true,
    },
];


/**
 * Find a MenuItem with given path
 * @param menuItems
 * @param path
 * @returns {null|*}
 */
export function findMenuItem(menuItems, path) {
    for (let item of menuItems) {
        if (item.link === path) {
            return item;
        }
        if (item.subItems && item.subItems.length > 0) {
            let found = findMenuItem(item.subItems, path);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

