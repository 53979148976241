import { createWebHistory, createRouter } from "vue-router";


import routes from './routes'
import AuthService from "@/services/AuthService";
import Alert from "@/helpers/Alert";
import {menuItems} from "../components/menu";
import {findMenuItem} from "../components/menu";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if (routeTo.name !== "login" && routeTo.name !== "mlogin" && !AuthService.isAuthenticated()) {
    next({ name: "mlogin" });
  } else {

    let roles = localStorage.getItem("roles");
    let rolesArray = roles ? roles.split(',').map(role => role.trim()) : [];

    // Check if user is admin. If they are, allow all pages.
    if (rolesArray.some(e => e == "web_admin")) {
      next();
    }

    let menuItem = findMenuItem(menuItems, routeTo.path);
    let roleRequired = menuItem != null ? menuItem.roleRequired : null;

    // Check that user has required role to access the page.
    if (roleRequired && roleRequired.length > 0) {
      let hasRole = roleRequired.some(role => rolesArray.includes(role));
      if (!hasRole) {
        console.log("Redirecting user from " + routeTo.path + " (" + routeTo.name +  ")");
        console.log("User has roles " + rolesArray);

        // Avoid infinite recursion
        if (routeTo.name === "default") {
          next({name: "self-care"})
        }

        next({ name: "default" });
      }
    }
    next();
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
      Alert.closeAlert();
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router
